import { ReactComponent as Star } from '../../images/star.svg';
import { repeat } from '../../utils/arrays';
import css from './index.module.css';

interface Props {
  onlyStars?: boolean;
  avgRating: number;
  reviewsCount?: number;
  className?: string;
}

const fiveStars = repeat(5, () => <Star className={css.starSVG} />);

export function RatingStars({
  onlyStars=false,
  avgRating,
  reviewsCount,
  className,
}: Props) {
  return (
    <div className={className ? className : ''}>
      {
        avgRating > 0
        ? (
          <div className={css.body}>
            {!onlyStars && (
              <span className={css.ratingValue}>{avgRating}</span>
            )}
            <div className={css.stars}>
              <div className={css.uncolored}>
                {fiveStars}
              </div>
              <div
                className={css.colored}
                style={{width: `calc(${avgRating} / 5 * 100%)`}}
              >
                {fiveStars}
              </div>
            </div>
            {reviewsCount && (
              <span className={css.reviewsCount}>({reviewsCount})</span>
            )}
          </div>
        ) : (
          <span className={css.noReviews}>Нет отзывов</span>
        )
      }
    </div>
  );
}
