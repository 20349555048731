import { ADDRESS } from '../../consts/data';
import css from './index.module.css';

export function HowToBuy() {
  return (
    <div className={`container ${css.container}`}>
      <h2 className={`h2 ${css.heading}`}>Условия доставки</h2>

      <ol className={css.deliveryMethods}>
        <li>
          <h4 className='h4'>Доставка по всей России</h4>
          <p>
            Наш магазин доставляет заказы транспортными компаниями СДЭК, ПЭК, Энергия до пункта выдачи или курьером до двери.
            Стоимость доставки зависит от габаритов и веса посылки.
            Точная стоимость доставки формируется при оформлении заказа по тарифам транспортной компании.
            Отправка посылки осуществляется в течение 1-2 дней после оформления заказа.
            Возможна отправка в день оформления.
          </p>
          <p>
            Минимальная сумма заказа:
            СДЭК - от любой суммы
            ПЭК и Энергия - от 5.000 рублей.
          </p>
        </li>
        <li>
          <h4 className='h4'>Доставка по Новосибирску</h4>
          <p>
            Доставка по Новосибирску осуществляется нашими курьерами или Яндекс Доставкой в день оформления заказа.
            Доставка оплачивается отдельно в зависимости от Вашего месторасположения.
          </p>
        </li>
        <li>
          <h4 className='h4'>Самовывоз</h4>
          <p>
            Также Вы можете самостоятельно забрать Ваш заказ в нашем магазине по адресу: {ADDRESS}.
            Предварительно свяжитесь с нашими менеджерами для уточнения наличия необходимых товаров и их бронирования.
            Бронь неоплаченного заказа: 1 рабочий день. 
          </p>
        </li>
      </ol>

      <strong className={css.strong}>
        Отправка товара курьерскими службами или транспортными компаниями осуществляется после полной оплаты Вашего заказа. 
      </strong>

      <h2 className={`h2 ${css.heading}`}>Способы оплаты</h2>
      <ol className={css.deliveryMethods}>
        <li>Наличными или картой при самовывозе</li>
        <li>Оплата через расчетный счет</li>
        <li>Оплата через сайт банковской картой, Sber pay, СБП.</li>
      </ol>
    </div>
  );
}
