import css from './index.module.css';

export function About() {
  return (
    <div className={`container ${css.container}`}>
      <p>
        История нашей компании «Proff clean market» началась в 2020 году с создания клининговой 
        компании в Сибири. Уже тогда мы были официальными представителя средств для уборки бренда Buzil.
      </p>
      <p>
        Спустя 2 года наша компания перепрофилировалась и сейчас мы являемся профессиональным 
        магазином для клининга, химчистки и уборки дома. На данный момент мы сотрудничаем
        уже более, чем с 20 брендами профессиональной химии для клининга и химчистки,
        являемся их официальными представителями.
      </p>
      <p>
        В миссии нашего бренда заложена профессиональная помощь в подборе средств и техники. Все 
        менеджеры Proff clean market, которые вас консультируют на разных площадках, проходят 
        профессиональное подготовку для грамотного подбора позиций по Вашему запросу.
      </p>
      <p>
        Мы помогаем клиентам не только с тем, чтобы они быстро и эффективно убирались дома, но и активно работаем с химчистками и клининговыми компаниями.
      </p>
      <p>
        У нас есть, как онлайн магазин, так и оффлайн магазин, где Вы можете получить консультацию и ознакомиться со всем нашим ассортиментом.
      </p>
      <p>
        Будем рады видеть Вас у нас в магазине!
      </p>
    </div>
  );
}
